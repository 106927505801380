// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { PageContext, AllMarkdownRemark } from '../types';

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext
};

const IndexTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const pageTitle = "Hey there, my name's Rabel 👋🏼";

  const pageBody = `
  <div>I am a computer scientist who is interested in cryptography, privacy, and finance. I currently work as a Software Engineer at <a href="https://www.betterment.com/" rel="noreferrer" target="_blank">Betterment</a>, based in New York City. Formerly I was a student at the University of Kansas where I graduated with a Bachelor of Science in Computer Science.</div>
  <br>
  <div>While at the University of Kansas, I helped start <a href="https://www.facebook.com/sparkatku/" rel="noreferrer" target='_blank'>Spark</a>, an organization dedicated to inspiring, educating, and exposing K-12 students to the wonders of technology.</div>
  <br>
  <div>A child, a teen, a college student, a grad student, and an expert, if I can successfully explain computer science concepts to any of these five levels, then I've truly mastered the field of computer science. This concept is inspired by my favorite <a href="https://www.wired.com/video/watch/5-levels-hacker-explains-one-concept-in-5-levels-of-difficulty?c=series" rel="noreferrer" target='_blank'>Wired series</a> called <i>5 Levels</i>, where a person does just this. Using my skillsets, I will be applying this same idea, as I explore different topics of interest here.</div>
  <br>
  <div>
    <img src="/media/nyc-1.gif" alt= "NYC Prince Street station">
    <center><i>NYC Prince Street station by Nana Rausch</i></center>
  </div>
  <br>
  `;

  return (
    <Layout title={siteTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page title= {pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    allMarkdownRemark(
        limit: $postsLimit,
        skip: $postsOffset,
        filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;

export default IndexTemplate;
